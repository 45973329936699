<template>
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
                class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
        <div class="mt-6 flex items-center justify-between px-6">
            <h4>Editar producto</h4>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
        </div>
        <vs-divider class="mb-0"></vs-divider>

        <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
            <div class="p-6">
                <!--Image-->
                <div class="flex -mx-2">
                    <div class="w-1/2 p-2">
                        <!-- Image 1 -->
                        <template v-if="product.image1">

                            <!-- Image Container -->
                            <div class="img-container flex items-center justify-center">
                                <img :src="product.image1" alt="img" class="responsive">
                            </div>

                            <!-- Image upload Buttons -->
                            <div class="modify-img flex justify-between mt-5">
                                <input type="file" class="hidden" ref="updateImgInput1" @change="updateCurrImg1"
                                       accept="image/*">
                                <span style="cursor: pointer" class="ml-2" type="flat"
                                      @click="$refs.updateImgInput1.click()">Cambiar
                                </span>
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="deleteImage1">Eliminar</span>
                            </div>
                        </template>
                    </div>
                    <div class="w-1/2 p-2">
                        <!-- Image 2 -->
                        <template v-if="product.image2">

                            <!-- Image Container -->
                            <div class="img-container flex items-center justify-center">
                                <img :src="product.image2" alt="img" class="responsive">
                            </div>

                            <!-- Image upload Buttons -->
                            <div class="modify-img flex justify-between mt-5">
                                <input type="file" class="hidden" ref="updateImgInput2" @change="updateCurrImg2"
                                       accept="image/*">
                                <span style="cursor: pointer" class="ml-2" type="flat"
                                      @click="$refs.updateImgInput2.click()">Cambiar
                                </span>
                                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                                      @click="deleteImage2">Eliminar</span>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="flex -mx-2">
                    <!-- Upload image 1 -->
                    <div class="upload-img mt-5 w-1/2 p-2" v-if="!product.image1">
                        <input type="file" class="hidden" ref="uploadImgInput1" @change="updateCurrImg1"
                               accept="image/*">
                        <vs-button style="width: 100%" @click="$refs.uploadImgInput1.click()">Primera imagen</vs-button>
                    </div>

                    <!-- Upload image 2-->
                    <div class="upload-img mt-5  w-1/2 p-2" v-if="!product.image2">
                        <input type="file" class="hidden" ref="uploadImgInput2" @change="updateCurrImg2"
                               accept="image/*">
                        <vs-button style="width: 100%" @click="$refs.uploadImgInput2.click()">Segunda imagen</vs-button>
                    </div>
                </div>
                <!--End-->

                <!--Features-->
                <div class="mt-5">
                    <hr class="mb-2">
                    <div class="flex -mx-2" v-for="(p, index) in product.features" :key="index">
                        <vs-input :disabled="true" label="Característica" v-model="p.name"
                                  class="w-1/2 p-2" name="feature"
                                  v-validate="'required'"/>
                        <vs-input @input="setSlug" label="Valor" v-model="p.value"
                                  class="w-1/2 p-2" name="featureValue"
                                  v-validate="'required'"/>
                    </div>
                    <hr class="mt-2">
                </div>
                <!--End-->

                <!-- SKU -->
                <vs-input @blur="product.sku= trimString(product.sku)" label="Código SAP" v-model="product.sku"
                          class="mt-5 w-full" name="sku"
                          v-validate="'required|min:3|max:50'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('sku')">{{ errors.first('sku') }}</span>
                <!--End-->

                <!-- Slug -->
                <vs-input :disabled="true" @blur="product.slug= trimString(product.slug)" label="Slug"
                          v-model="product.slug"
                          class="mt-5 w-full" name="slug"
                          v-validate="'required'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('slug')">{{ errors.first('slug') }}</span>
                <!--End-->

                <!--Stock-->
                <vs-input-number class="mt-5 mb-5" label="Estimación:" v-model.number="product.stock" name="stock"
                                 v-validate="'required|numeric|min_value:1'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('stock')">{{ errors.first('stock') }}</span>
                <!--End-->

                <!---Prices--->
                <div class="flex -mx-2">
                    <!-- Normal price -->
                    <div class="w-1/2 p-2">
                        <vs-input label="Precio normal" v-model.number="product.normalPrice" class="w-full"
                                  name="normalPrice"
                                  v-validate="'required|min_value:1'"/>
                        <span class="text-danger text-sm"
                              v-show="errors.has('normalPrice')">{{ errors.first('normalPrice') }}</span>
                    </div>
                    <div class="w-1/2 p-2">
                        <!-- Price Discount -->
                        <vs-input label="Precio con descuento" v-model.number="product.priceDiscount"
                                  class="w-full"
                                  name="priceDiscount"
                                  v-validate="'required|min_value:1'"/>
                        <span class="text-danger text-sm"
                              v-show="errors.has('priceDiscount')">{{ errors.first('priceDiscount') }}</span>
                    </div>
                </div>
                <!--End-->

                <!-- Description -->
                <vs-textarea @blur="product.description= trimString(product.description)" label="Descripción"
                             v-model="product.description" class="mt-5 w-full"
                             name="description"
                             v-validate="'required|min:3|max:1000'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('description')">{{ errors.first('description') }}</span>
                <!--End-->

                <!-- Order -->
                <vs-input @input="setSlug" @blur="product.order= trimString(product.order)" label="Orden"
                          v-model="product.order"
                          class="mt-5 w-full" name="order"
                          v-validate="'required|min_value:0'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('order')">{{ errors.first('order') }}</span>
                <!--End-->

                <!-- Related products -->
                <div class="mt-5">
                    <label class="vs-select--label mt-5" for="relatedProducts">Selecciona los productos
                        relacionados</label>
                    <v-select id="relatedProducts" multiple label="name" :options="relatedProducts"
                              v-model="product.relatedProducts"
                              :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
                </div>
                <!--End-->

                <!--SAP-->
                <div class="mt-5">
                    <hr class="mb-2">
                    <!-- Unity sap -->
                    <vs-input @blur="product.unitySAP= trimString(product.unitySAP)"
                              label="Unidad de medida SAP"
                              v-model="product.unitySAP"
                              class="w-full" name="unitySAP"
                              v-validate="'required|min:3|max:50'"/>
                    <span class="text-danger text-sm"
                          v-show="errors.has('unitySAP')">{{ errors.first('unitySAP') }}</span>
                    <!--End-->
                    <!--Descripction SAP-->
                    <vs-textarea @blur="product.descriptionSAP= trimString(product.descriptionSAP)"
                                 label="Descripción SAP"
                                 v-model="product.descriptionSAP" class="mt-5 w-full"
                                 name="descriptionSAP"
                                 v-validate="'required|min:3|max:50'"/>
                    <span class="text-danger text-sm"
                          v-show="errors.has('descriptionSAP')">{{ errors.first('descriptionSAP') }}</span>
                    <!--End-->
                    <hr class="mt-2">
                </div>
                <!--End-->
            </div>
        </component>

        <div class="flex flex-wrap items-center p-6" slot="footer">
            <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Modificar</vs-button>
            <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
            <div v-if="progress" style="margin-top: 1rem; width: 100%">
                <vs-progress indeterminate color="primary"></vs-progress>
            </div>
        </div>
    </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import trimString from "../../mixins/trimString";
  import vSelect from 'vue-select'

  const firebase = require("firebase")
  // Required for side-effects
  require("firebase/firestore")
  let db = firebase.firestore()
  export default {
    name: 'UpdateVariant',
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        }
      },
      relatedProducts: {
        type: Array,
        required: true
      }
    },
    components: {
      VuePerfectScrollbar,
      vSelect
    },
    mixins: [trimString],
    data() {
      return {
        product: {
          priceDiscount: 0,
          normalPrice: 0
        },
        oldStock: 0,

        newImage1: null,
        newImage2: null,

        settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60
        },
        progress: false,
        deleteImageFlag: false
      }
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return
        let relatedProducts = this.data.relatedProducts
        if (relatedProducts) {
          relatedProducts.forEach((r) => {
            r.labelName = r.name + "-" + r.features[0].value + "" + r.features[1].value
          })
        }
        this.product = {
          ...this.data
        }
        this.oldStock = this.data.stock
        this.newImage1 = null
        this.newImage2 = null

      }
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
          }
        }
      },
      isFormValid() {
        return !this.errors.any()
      },
      scrollbarTag() {
        return this.$store.getters.scrollbarTag
      }
    },
    methods: {
      submitData() {
        this.$validator.validateAll().then(async result => {
          if (result) {
            this.progress = true
            if (this.newImage1 != null) {
              this.product.image1 = await this.submitImages(this.newImage1)
            }
            if (this.newImage2 != null) {
              this.product.image2 = await this.submitImages(this.newImage2)
            }
            // Update product
            let productRef = db.collection('products').doc(this.product.id)

            //Clone to remove the id
            let cloneProduct = Object.assign({}, this.product)
            delete cloneProduct.id

            productRef.update({
              ...cloneProduct,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp()
            }).then(async () => {
              let obj = {
                ...this.product
              }
              await this.$store.dispatch('variants/updateProduct', {variant: obj})
              this.progress = false

              //If the stock changes, the history is saved.
              if (this.product.stock !== this.oldStock) {
                await this.historyStock()
              }

              this.$vs.notify({
                color: 'success',
                title: 'Producto',
                text: 'Producto modificado correctamente.'
              })

              this.$emit('closeSidebar')

            }).catch(() => {
              this.$vs.notify({
                color: 'warning',
                title: '¡Oh no!',
                text: 'Hubo un error.'
              })
            })
          }
        })
      },
      submitImages(image) {
        return new Promise((resolve, reject) => {
          let imageName = Math.random().toString(36).substr(1, 20)
          let fileExtension = '.' + image.name.split('.').pop()
          let imageNameFinal = imageName + fileExtension

          let storageRef = firebase.storage().ref(`products/${imageNameFinal}`)
          storageRef.put(image)
           .then((snapshot) => {
             snapshot.ref.getDownloadURL().then((url) => {
               resolve(url)
             })
           })
           .catch((error) => {
             reject(error)
           })
        })
      },
      historyStock() {
        new Promise((resolve, reject) => {
          db.collection('historyStock').add({
            quantity: this.product.stock - this.oldStock,
            reason: 'Product update',
            productName: this.product.name,
            productSKU: this.product.sku,
            productId: this.product.id,
            uid: firebase.auth().currentUser.uid,
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
          }).then(() => {
            resolve()
          }).catch((error) => {
            reject(error)
          })
        })
      },
      updateCurrImg1(input) {
        if (input.target.files && input.target.files[0]) {
          const reader = new FileReader()
          reader.onload = e => {
            this.product.image1 = e.target.result
            this.newImage1 = input.target.files[0]
          }
          reader.readAsDataURL(input.target.files[0])
        }
      },
      updateCurrImg2(input) {
        if (input.target.files && input.target.files[0]) {
          const reader = new FileReader()
          reader.onload = e => {
            this.product.image2 = e.target.result
            this.newImage2 = input.target.files[0]
          }
          reader.readAsDataURL(input.target.files[0])
        }
      },
      initValues() {
        if (this.data.id) return
        this.product = {
          state: false,
          image1: null,
          image2: null,
          stock: 0,
          features: []
        }
      },
      deleteImage1() {
        this.product.image1 = null
        this.newImage1 = null
      },
      deleteImage2() {
        this.product.image2 = null
        this.newImage2 = null
      },
      setSlug() {
        this.product.slug = this.getSlug(this.product.name + " " + this.product.features[0].value + " " + this.product.features[1].value)
      },
      getSlug(name) {
        let text = (name).trim().split(' ').join('-').toLowerCase().normalize('NFD')
         .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
         .normalize();
        return text
      }
    }
  }
</script>

<style lang="scss" scoped>
    .add-new-data-sidebar {
        ::v-deep .vs-sidebar--background {
            z-index: 52010;
        }

        ::v-deep .vs-sidebar {
            z-index: 52010;
            width: 650px;
            max-width: 90vw;

            .img-upload {
                margin-top: 2rem;

                .con-img-upload {
                    padding: 0;
                }

                .con-input-upload {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    .scroll-area--data-list-add-new {
        // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
        height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

        &:not(.ps) {
            overflow-y: auto;
        }
    }
</style>
