<template>
    <div id="data-list-list-view" class="data-list-container">
        <div v-if="initProgress" class="w-100 mt-5 mb-5">
            <vs-progress indeterminate color="primary"></vs-progress>
        </div>

        <vs-prompt title="Exportar a Excel" class="export-options" @cancle="clearFields" @accept="exportToExcel"
                   accept-text="Exportar" cancel-text="Cancelar" @close="clearFields" :active.sync="activePrompt">
            <vs-input v-model="fileName" placeholder="Ingresa el nombre del archivo..." class="w-full"/>
            <v-select v-model="selectedFormat" :options="formats" class="my-4"/>
            <div class="flex">
                <span class="mr-4">Auto ajustar columna:</span>
                <vs-switch v-model="cellAutoWidth">Auto ajustar columna</vs-switch>
            </div>
        </vs-prompt>

        <vx-card v-if="filter" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
                 refresh-content-action @refresh="resetFilters">
            <div class="vx-row">
                <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
                    <label class="text-sm opacity-75">Producto principal</label>
                    <v-select label="name" :options="productsOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                              v-model="productFilter" class="mb-4 md:mb-0"/>
                </div>
                <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
                    <label class="text-sm opacity-75">Filtrar por stock</label>
                    <v-select :options="stocksOptions" :clearable="false"
                              :dir="$vs.rtl ? 'rtl' : 'ltr'"
                              v-model="stockFilter" class="mb-4 md:mb-0"/>
                </div>
            </div>
        </vx-card>

        <new :relatedProducts="relatedProducts" :productsOptions="productsOptions" :isSidebarActive="newSidebar"
             @closeSidebar="toggleNewSidebar"
             :data="sidebarData"/>
        <update :relatedProducts="relatedProducts" :isSidebarActive="updateSidebar" @closeSidebar="toggleUpdateSidebar"
                :data="sidebarData"/>
        <features :isSidebarActive="featureSideBar" @closeSidebar="toggleFeatureSidebar" :data="sidebarData"/>

        <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
                  :data="products">
            <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

                <div class="flex flex-wrap-reverse items-center data-list-btn-container">
                    <!-- Action - dropnow -->
                    <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4">

                        <div class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
                            <span class="mr-2">Acciones</span>
                            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
                        </div>

                        <vs-dropdown-menu>
                            <vs-dropdown-item @click="editData(selected)">
                            <span class="flex items-center">
                              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Editar</span>
                            </span>
                            </vs-dropdown-item>
                            <vs-dropdown-item @click="filterData">
                            <span class="flex items-center">
                              <feather-icon icon="FilterIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Filtrar</span>
                            </span>
                            </vs-dropdown-item>
                            <vs-dropdown-item @click="activePrompt=true">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar</span>
                            </span>
                            </vs-dropdown-item>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                    <!-- Add new -->
                    <vs-button :disabled="initProgress" color="danger" type="border" class="mr-6" @click="addNewData">
                        Agregar
                    </vs-button>
                </div>

                <!-- Items per page -->
                <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
                    <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ products.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : products.length }} de {{ queriedItems }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
                    </div>
                    <vs-dropdown-menu>

                        <vs-dropdown-item @click="itemsPerPage=10">
                            <span>10</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="itemsPerPage=20">
                            <span>20</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="itemsPerPage=30">
                            <span>30</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="itemsPerPage=40">
                            <span>40</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </div>

            <template slot="thead">
                <vs-th sort-key="sku">SKU</vs-th>
                <vs-th sort-key="name">Nombre</vs-th>
                <vs-th sort-key="category">Categoría</vs-th>
                <vs-th sort-key="slug">Presentación</vs-th>
                <vs-th sort-key="stock">Stock</vs-th>
                <vs-th sort-key="normalPrice">Precio Normal</vs-th>
                <vs-th sort-key="priceDiscount">Precio Dcto.</vs-th>
                <vs-th>Estado</vs-th>
                <vs-th>Acciones</vs-th>
            </template>

            <template slot-scope="{data}">
                <tbody>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                    <vs-td>
                        <p class="product-name font-medium truncate">{{ tr.sku }}</p>
                    </vs-td>

                    <vs-td>
                        <p class="product-name font-medium truncate">{{ tr.name }}</p>
                    </vs-td>

                    <vs-td>
                        <p class="product-name font-medium truncate">{{ tr.categoryName }}</p>
                    </vs-td>

                    <vs-td>
                        <p class="product-name font-medium truncate">
                            {{ tr.features[0].value +" " + tr.features[1].value}}
                        </p>
                    </vs-td>

                    <vs-td>
                        <p class="product-name font-medium truncate">{{ tr.stock }}</p>
                    </vs-td>

                    <vs-td>
                        <p class="product-name font-medium truncate">{{ tr.normalPrice }}</p>
                    </vs-td>

                    <vs-td>
                        <p class="product-name font-medium truncate">{{ tr.priceDiscount }}</p>
                    </vs-td>

                    <vs-td>
                        <vs-switch @input="switchState(tr)" v-model="tr.state"/>
                    </vs-td>

                    <vs-td class="whitespace-no-wrap">
                        <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                      @click.stop="editData(tr)"/>
                        <feather-icon class="ml-2" icon="FileTextIcon"
                                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                      @click.stop="featureData(tr)"/>
                        <feather-icon class="ml-2" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                      @click.stop="viewProduct(tr)"/>
                    </vs-td>
                </vs-tr>
                </tbody>
            </template>
        </vs-table>
    </div>
</template>

<script>
  import New from './New'
  import Update from './Update'
  import Features from './Features.vue'
  import vSelect from 'vue-select'

  const firebase = require("firebase")
  // Required for side-effects
  require("firebase/firestore")
  let db = firebase.firestore()

  export default {
    name: 'ListVariants',
    components: {
      New,
      Update,
      Features,
      vSelect
    },
    data() {
      return {
        itemsPerPage: 10,
        isMounted: false,
        productsPre: [],
        initProgress: false,
        selected: null,
        filter: false,

        //Filters
        productsOptions: [],
        relatedProducts: [],
        stocksOptions: [
          {label: 'Todos', value: -1},
          {label: 'Sin stock', value: 0},
          {label: 'Por acabarse', value: 10},
        ],
        productFilter: {},
        stockFilter: {
          label: 'Todos',
          value: -1
        },
        //

        // Data Sidebar
        newSidebar: false,
        updateSidebar: false,
        featureSideBar: false,
        sidebarData: {},
        // Export
        fileName: '',
        formats: ['xlsx', 'csv', 'txt'],
        cellAutoWidth: true,
        selectedFormat: 'xlsx',
        headerTitle: ['Nombre', 'SKU', 'Stock', 'Precio con descuento', 'Precio normal', 'Estado'],
        headerVal: ['name', 'sku', 'stock', 'normalPrice', 'priceDiscount', 'state'],
        activePrompt: false
      }
    },
    async created() {
      this.initProgress = true
      // Reset list
      this.productsPre = []
      await this.$store.dispatch('variants/fetchProducts', {variants: this.productsPre})
      //Get products
      await this.loadProducts()
      await this.loadRelatedProducts()
      this.initProgress = false
    },
    mounted() {
      this.isMounted = true
    },
    computed: {
      products: {
        get() {
          return this.$store.state.variants.variants
        }
      },
      currentPage() {
        if (this.isMounted) {
          return this.$refs.table.currentx
        }
        return 0
      },
      queriedItems() {
        return this.$refs.table ? this.$refs.table.queriedResults.length : this.products.length
      }
    },
    watch: {
      async productFilter(val) {
        if (val.value !== -1) {
          this.initProgress = true
          this.productsPre = []
          await this.$store.dispatch('variants/fetchProducts', {variants: this.productsPre})
          db.collection("products").where('productId', '==', this.productFilter.id)
           .orderBy('createdAt', 'desc').get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let product = {
                id: doc.id,
                ...doc.data()
              }
              if (!product.unitySAP) {
                product.unitySAP = ""
              }
              if (!product.descriptionSAP) {
                product.descriptionSAP = ""
              }
              this.productsPre.push(product)
            })
            this.initProgress = false
            this.$store.dispatch('variants/fetchProducts', {variants: this.productsPre})
          }).catch(() => {
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          })
        }
      },
      async stockFilter(val) {
        if (val.value !== -1) {
          this.initProgress = true
          this.productsPre = []
          await this.$store.dispatch('variants/fetchProducts', {products: this.productsPre})
          db.collection("products").where('stock', '<=', this.stockFilter.value).get()
           .then((querySnapshot) => {
             querySnapshot.forEach((doc) => {
               let product = {
                 id: doc.id,
                 ...doc.data()
               }
               if (!product.unitySAP) {
                 product.unitySAP = ""
               }
               if (!product.descriptionSAP) {
                 product.descriptionSAP = ""
               }
               this.productsPre.push(product)
             })

             this.initProgress = false
             this.$store.dispatch('variants/fetchProducts', {products: this.productsPre})
           }).catch(() => {
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          })
        }
      }
    },
    methods: {
      addNewData() {
        this.sidebarData = {}
        this.newSidebar = true
      },
      editData(data) {
        if (data) {
          this.sidebarData = data
          this.toggleUpdateSidebar(true)
        }
      },
      featureData(data) {
        if (data) {
          this.sidebarData = data
          this.toggleFeatureSidebar(true)
        }
      },
      filterData() {
        this.filter = !this.filter
      },
      toggleNewSidebar(val = false) {
        this.newSidebar = val
      },
      toggleUpdateSidebar(val = false) {
        this.updateSidebar = val
      },
      toggleFeatureSidebar(val = false) {
        this.featureSideBar = val
      },
      switchState(product) {
        let productRef = db.collection('products').doc(product.id)
        productRef.update({
          state: product.state
        }).then(() => {
          this.$store.dispatch('variants/updateProduct', {variant: product})
          this.$vs.notify({
            color: 'success',
            title: 'Producto',
            text: 'La modificación fue aplicada.'
          })
        }).catch(() => {
          this.$vs.notify({
            color: 'warning',
            title: '¡Oh no!',
            text: 'Hubo un error.'
          })
        })
      },
      switchBestSeller(product) {
        let productRef = db.collection('products').doc(product.id)
        productRef.update({
          bestSeller: product.bestSeller
        }).then(() => {
          this.$store.dispatch('variants/updateProduct', {variant: product})
          this.$vs.notify({
            color: 'success',
            title: 'Producto',
            text: 'La modificación fue aplicada.'
          })
        }).catch(() => {
          this.$vs.notify({
            color: 'warning',
            title: '¡Oh no!',
            text: 'Hubo un error.'
          })
        })
      },
      loadProducts() {
        return new Promise((resolve, rejected) => {
          db.collection("products")
           .orderBy('createdAt', 'desc')
           .where('hasVariants', '==', "true")
           .where('state', '==', true)
           .get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let product = {
                id: doc.id,
                ...doc.data()
              }
              this.productsOptions.push(product)
            })
            resolve('ok')
          }).catch((error) => {
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
            rejected(error)
          })
        })
      },
      loadRelatedProducts() {
        return new Promise((resolve, rejected) => {
          db.collection("products")
           .orderBy('createdAt', 'desc')
           .get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let product = {
                id: doc.id,
                name: doc.data().name,
                image1: doc.data().image1,
                features: doc.data().features,
                state: doc.data().state
              }

              if (product.features && product.state) {
                product.labelName = product.name + "-" + product.features[0].value + "" + product.features[1].value
                this.relatedProducts.push(product)
              }
            })
            resolve('ok')
          }).catch((error) => {
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
            rejected(error)
          })
        })
      },
      async resetFilters() {
        this.initProgress = true
        await this.loadProducts()
        await this.relatedProducts()
        this.initProgress = false

        this.productFilter = {
          label: 'Todos',
          value: -1
        }
        this.stockFilter = {
          label: 'Todos',
          value: -1
        }
        this.$refs.filterCard.removeRefreshAnimation()
      },
      exportToExcel() {
        import('@/vendor/Export2Excel').then(excel => {
          const list = []
          this.productsPre.forEach((p) => {
            let product = {
              name: p.name,
              sku: p.sku,
              stock: p.stock,
              priceDiscount: p.priceDiscount,
              normalPrice: p.normalPrice,
              state: p.state
            }
            list.push(product)
          })
          const data = this.formatJson(this.headerVal, list)
          excel.export_json_to_excel({
            header: this.headerTitle,
            data,
            filename: this.fileName,
            autoWidth: this.cellAutoWidth,
            bookType: this.selectedFormat
          })
          this.clearFields()
        })
      },
      clearFields() {
        this.filename = ''
        this.cellAutoWidth = true
        this.selectedFormat = 'xlsx'
      },
      formatJson(filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => {
          // Add col name which needs to be translated
          // if (j === 'timestamp') {
          //   return parseTime(v[j])
          // } else {
          //   return v[j]
          // }

          return v[j]
        }))
      },
      viewProduct(product) {
        window.open(
         'https://royal.com.pe/products/' + product.slug,
         '_blank'
        )
      }
    }
  }
</script>

<style lang="scss">
    #data-list-list-view {
        .vs-con-table {

            /*
              Below media-queries is fix for responsiveness of action buttons
              Note: If you change action buttons or layout of this page, Please remove below style
            */
            @media (max-width: 689px) {
                .vs-table--search {
                    margin-left: 0;
                    max-width: unset;
                    width: 100%;

                    .vs-table--search-input {
                        width: 100%;
                    }
                }
            }

            @media (max-width: 461px) {
                .items-per-page-handler {
                    display: none;
                }
            }

            @media (max-width: 341px) {
                .data-list-btn-container {
                    width: 100%;

                    .dd-actions,
                    .btn-add-new {
                        width: 100%;
                        margin-right: 0 !important;
                    }
                }
            }

            .product-name {
                max-width: 23rem;
            }

            .vs-table--header {
                display: flex;
                flex-wrap: wrap;
                margin-left: 1.5rem;
                margin-right: 1.5rem;

                > span {
                    display: flex;
                    flex-grow: 1;
                }

                .vs-table--search {
                    padding-top: 0;

                    .vs-table--search-input {
                        padding: 0.9rem 2.5rem;
                        font-size: 1rem;

                        & + i {
                            left: 1rem;
                        }

                        &:focus + i {
                            left: 1rem;
                        }
                    }
                }
            }

            .vs-table {
                border-collapse: separate;
                border-spacing: 0 1.3rem;
                padding: 0 1rem;

                tr {
                    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

                    td {
                        padding: 20px;

                        &:first-child {
                            border-top-left-radius: .5rem;
                            border-bottom-left-radius: .5rem;
                        }

                        &:last-child {
                            border-top-right-radius: .5rem;
                            border-bottom-right-radius: .5rem;
                        }
                    }

                    td.td-check {
                        padding: 20px !important;
                    }
                }
            }

            .vs-table--thead {
                th {
                    padding-top: 0;
                    padding-bottom: 0;

                    .vs-table-text {
                        text-transform: uppercase;
                        font-weight: 600;
                    }
                }

                th.td-check {
                    padding: 0 15px !important;
                }

                tr {
                    background: none;
                    box-shadow: none;
                }
            }

            .vs-table--pagination {
                justify-content: center;
            }
        }
    }
</style>
