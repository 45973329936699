<template>
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
                class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
        <div class="mt-6 flex items-center justify-between px-6">
            <h4>Características de {{product.name}}</h4>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
        </div>
        <vs-divider class="mb-0"></vs-divider>

        <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
            <!--Default add feature-->
            <div class="p-6">
                <div>
                    <!-- Features -->
                    <vs-select v-model="newFeature" label="Características"
                               class="w-full" name="feature">
                        <vs-select-item :key="index" :value="item.id" :text="item.name"
                                        v-for="(item,index) in features"/>
                    </vs-select>
                    <span class="text-danger text-sm"
                          v-show="errors.has('feature')">{{ errors.first('feature') }}</span>
                </div>

                <vs-button class="mr-6 mt-2" @click="addFeature" :disabled="!isFormValid || progress">Agregar
                </vs-button>
            </div>

            <!--Products features-->
            <div class="pl-6 pr-6" v-for="(f, index) in product.features" :key="index">
                <template v-if="f.tag!=='volume' && f.tag!=='unity'">
                    <div class="grid grid-cols-7 gap-3">
                        <div class="col-span-6">
                            <!-- Features -->
                            <vs-input :disabled="true" label="Nombre" v-model="f.name"
                                      class="mt-5 w-full"
                                      v-validate="'required'"/>
                        </div>

                        <div class="col-span-1 flex justify-center items-end pb-5">
                            <feather-icon style="cursor:pointer" icon="TrashIcon"
                                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                          @click.stop="deleteFeature(f)"/>
                        </div>
                    </div>
                </template>
            </div>

        </component>

        <div class="flex flex-wrap items-center p-6" slot="footer">
            <vs-button class="mr-6" @click="submitData" :disabled="!this.changes||progress">Guardar</vs-button>
            <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
            <div v-if="progress" style="margin-top: 1rem; width: 100%">
                <vs-progress indeterminate color="primary"></vs-progress>
            </div>
        </div>
    </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import trimString from "../../mixins/trimString";

  const firebase = require("firebase")
  // Required for side-effects
  require("firebase/firestore")
  let db = firebase.firestore()
  export default {
    name: 'FeaturesProducts',
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        }
      }
    },
    components: {
      VuePerfectScrollbar
    },
    mixins: [
      trimString
    ],
    data() {
      return {
        product: {},
        features: [],
        newFeature: -1,
        settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60
        },
        changes: false,
        progress: false,
      }
    },
    mounted() {
      //Get categories
      let obj = {
        id: -1,
        name: 'Selecciona una característica'
      }
      this.features.push(obj)
      db.collection("features").orderBy('createdAt', 'desc').get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let feature = {
            ...doc.data()
          }
          if (feature.tag !== 'volume' && feature.tag !== 'unity') {
            this.features.push(feature)
          }
        })
      })
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return
        this.$validator.reset()
        if (this.data.features) {
          this.product = {
            ...this.data
          }
        } else {
          this.product = {
            ...this.data,
            features: []
          }
        }
      }
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
          }
        }
      },
      isFormValid() {
        return !this.errors.any() && this.newFeature && this.newFeature !== -1
      },
      scrollbarTag() {
        return this.$store.getters.scrollbarTag
      }
    },
    methods: {
      submitData() {
        this.progress = true
        let productRef = db.collection('products').doc(this.product.id)
        productRef.update({
          features: this.product.features,
          updateAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid: firebase.auth().currentUser.uid
        }).then(() => {
          let obj = {
            ...this.product
          }
          this.$store.dispatch('variants/updateProduct', {variant: obj})
          this.progress = false
          this.changes = false
          this.$vs.notify({
            color: 'success',
            title: 'Producto',
            text: 'Características actualizadas.'
          })
        }).catch(() => {
          this.$vs.notify({
            color: 'warning',
            title: '¡Oh no!',
            text: 'Hubo un error.'
          })
        })
      },
      initValues() {
        this.product = {
          features: []
        }
        this.newFeature = -1
      },
      addFeature() {
        try {
          if (this.validateNoRepeat()) {
            // Update product features
            let productRef = db.collection('products').doc(this.product.id)
            // Find Feature
            this.newFeature = this.features.find(f => f.id === this.newFeature)
            let featureClone = {
              ...this.newFeature
            }
            this.product.features.push(featureClone)
            productRef.update({
              features: this.product.features,
              updateAt: firebase.firestore.FieldValue.serverTimestamp(),
              uid: firebase.auth().currentUser.uid
            }).then(() => {
              this.newFeature = -1
              this.$validator.reset()

              let obj = {
                ...this.product
              }
              this.$store.dispatch('variants/updateProduct', {variant: obj})

              this.$vs.notify({
                color: 'success',
                title: 'Producto',
                text: 'Característica agregada.'
              })
            })
          } else {
            this.$vs.notify({
              color: 'warning',
              title: 'Producto',
              text: 'No pueden repetirse características.'
            })
          }
        } catch (e) {
          this.$vs.notify({
            color: 'warning',
            title: '¡Oh no!',
            text: 'Hubo un error.'
          })
        }
      },
      deleteFeature(feature) {
        this.changes = true
        this.product.features.forEach((f, index) => {
          if (f.id === feature.id) {
            this.product.features.splice(index, 1)
          }
        })
      },
      changesAlert() {
        this.changes = true
      },
      validateNoRepeat() {
        let flag = 0
        this.product.features.forEach((f) => {
          if (f.id === this.newFeature) {
            flag = 1
          }
        })

        if (flag === 0) {
          return true
        } else {
          return false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .add-new-data-sidebar {
        ::v-deep .vs-sidebar--background {
            z-index: 52010;
        }

        ::v-deep .vs-sidebar {
            z-index: 52010;
            width: 700px;
            max-width: 90vw;

            .img-upload {
                margin-top: 2rem;

                .con-img-upload {
                    padding: 0;
                }

                .con-input-upload {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    .scroll-area--data-list-add-new {
        // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
        height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

        &:not(.ps) {
            overflow-y: auto;
        }
    }
</style>
